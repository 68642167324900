
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider, Page, Layout,LegacyCard, CalloutCard, Text, Button, TextField} from '@shopify/polaris';
import { useState, useCallback } from 'react';


function App() {
  const [value,setValue] = useState("Jade Color");
  const handleChange = useCallback(
    (newValue) => setValue(newValue),
    [],
  );

  return (
    <AppProvider i18n={enTranslations}>
    <Page title="Home">
      <Layout>
        <Layout.Section>
          <LegacyCard title="Online store dashboard" sectioned>
            <LegacyCard.Section>
              <Text as="h2" variant="bodyMd">
                <Button onClick={() => alert('Button clicked!')}>Example button</Button>
              </Text>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>          
            <CalloutCard 
              title="Write your code"
              illustration="https://app.jsrates.com/assets/img/editor-callout-icon.png"
              primaryAction={{
                content: 'Launch your code',
                url: '#',                
              }}           
            >               
                <p>Start by creating custom JavaScript shipping rules with JsRates. Use our AI-assisted code generators or our intuitive interface to develop tailored solutions for your specific business requirements.</p>
            </CalloutCard>
        </Layout.Section>
        <Layout.Section>          
            <CalloutCard 
              title="Need help with shipping setup?"
              illustration="https://app.jsrates.com/assets/img/support-callout-icon.png"
              primaryAction={{
                content: 'Contact support',
                url: '#',
              }}
              children={<p>If you have any questions or need help with setting up your shipping rates, please feel free to reach out. Our team is here to provide the assistance you need to ensure your shipping configurations are tailored to your business needs and set up effectively.</p>}
            />               

        </Layout.Section>
        <Layout.Section>          
            <TextField
              label="Store name" 
              value={value}
              onChange={handleChange}
              autoComplete='off'
            /> 
        </Layout.Section>

        <Layout.Section>          
            <TextField 
              label="Parrot" 
              value={value}
            />             
        </Layout.Section>
        
      </Layout>
    </Page>
  </AppProvider>
  );
}

export default App;
